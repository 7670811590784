import { useBi } from '@wix/yoshi-flow-editor';
import { riseWixPluginLoadedSrc35Evid200 } from '@wix/bi-logger-rise-ai-data/v2';
import { useApplicationContext } from './useApplicationContext/useApplicationContext';
import { useRef } from 'react';

export const useLoadedBiEvent = () => {
  const bi = useBi();
  const { appData } = useApplicationContext();
  const isReported = useRef(false);

  const reportLoadedBiEvent = () => {
    if (!isReported.current) {
      isReported.current = true;
      bi.report(
        riseWixPluginLoadedSrc35Evid200({
          appId: appData.appId,
          appName: appData.appName || '',
        }),
      );
    }
  };

  return { reportLoadedBiEvent };
};
