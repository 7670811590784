import { useBi } from '@wix/yoshi-flow-editor';
import { riseWixPluginClickSrc35Evid201 } from '@wix/bi-logger-rise-ai-data/v2';
import { useApplicationContext } from './useApplicationContext/useApplicationContext';
import { useLocaleKeys } from '../../../../locale-keys/LocaleKeys';

export const useClickBiEvent = () => {
  const bi = useBi();
  const { isWalletEmpty, appData, userTotalStoreCreditNumber } =
    useApplicationContext();
  const localeKeys = useLocaleKeys();

  const errorName = isWalletEmpty
    ? localeKeys.checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage()
    : localeKeys.checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage();

  const reportClickBiEvent = ({ buttonName }: { buttonName: string }) => {
    bi.report(
      riseWixPluginClickSrc35Evid201({
        appId: appData.appId,
        appName: appData.appName || '',
        buttonName,
        state: isWalletEmpty ? 'disabled' : '',
        errorName,
        balance: userTotalStoreCreditNumber! * 100000,
      }),
    );
  };
  return { reportClickBiEvent };
};
