import React, { FC } from 'react';
import { Text, TextPriority } from 'wix-ui-tpa/cssVars';
import { Info } from '@wix/wix-ui-icons-common/on-stage';
import { classes } from './ErrorSection.st.css';
import { useLocaleKeys } from '../../../../locale-keys/LocaleKeys';
import { useApplicationContext } from '../../core/hooks/useApplicationContext/useApplicationContext';

export enum ErrorSectionDataHooks {
  Container = 'error-section-container',
  ErrorText = 'error-section-text',
  Icon = 'error-section-icon',
}

export const ErrorSection: FC = () => {
  const { isWalletEmpty } = useApplicationContext();
  const localeKeys = useLocaleKeys();

  return (
    <div
      className={classes.container}
      data-hook={ErrorSectionDataHooks.Container}
    >
      <Info
        className={classes.infoIcon}
        data-hook={ErrorSectionDataHooks.Icon}
      />
      <Text
        className={classes.errorText}
        data-hook={ErrorSectionDataHooks.ErrorText}
        priority={TextPriority.secondary}
      >
        {isWalletEmpty
          ? localeKeys.checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage()
          : localeKeys.checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage()}
      </Text>
    </div>
  );
};
